<template>
  <b-modal @shown="updateItem" title="輔導問卷檢視" id="surveyItem" size="xl">
    <b-container fluid>
      <main class="main-grid-dtc">
        <Ask901
          :allData="allData"
          :key="'key1' + Object.keys(allData).length"
          v-if="questionNumber == 1"
        ></Ask901>
        <Ask902
          :allData="allData"
          :key="'key2' + Object.keys(allData).length"
          v-else-if="questionNumber == 2"
        ></Ask902>
        <AskInService
          :allData="allData"
          :key="'key3' + Object.keys(allData).length"
          v-else-if="questionNumber == 3"
        ></AskInService>
        <Ask1001
          :allData="allData"
          :key="'key4' + Object.keys(allData).length"
          v-else-if="questionNumber == 4"
        ></Ask1001>
        <AskResign
          :allData="allData"
          :key="'key5' + Object.keys(allData).length"
          v-else-if="questionNumber == 5"
        ></AskResign>
      </main>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button variant="success" class="float-right mr-5" @click="closeDlg"
          >確定</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store, actions } from "@/store/global.js";
import Ask901 from "./Ask901.vue";
import Ask902 from "./Ask902.vue";
import AskInService from "./AskInService.vue";
import Ask1001 from "./Ask1001.vue";
import AskResign from "./AskResign.vue";
export default {
  name: "EditPersonItem",
  data() {
    return {
      item: {},
      id: "",
      year: "",
      allData: {},
      questionNumber: 0,
    };
  },
  components: {
    Ask901,
    Ask902,
    AskInService,
    Ask1001,
    AskResign,
  },
  computed: {},
  methods: {
    closeDlg() {
      this.$bvModal.hide("surveyItem");
      store.editPerson.closeBtn = false;
    },
    async updateItem() {
      this.item = { ...store.editPerson };
      this.year = this.item.Year ? this.item.Year : "";
      this.id = this.item.Identifier ? this.item.Identifier : "";

      try {
        let qs = `?identifier=${this.id}&year=${this.year}`;
        let Items = await axios.get("LocalRecord/GetAnswer" + qs);
        this.allData = { ...Items };
        this.questionNumber = this.allData.Question ? this.allData.Question : 0;
      } catch (e) {
        alert("" + e);
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
p {
  font-size: 2em;
  text-align: center;
}
.input-group-text {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}
.code-dtc {
  display: grid;
  grid-template-columns: 60px minmax(400px, 525px);
}
.input-group {
  margin-bottom: 10px;
}
::v-deep #surveyItem > .modal-dialog > .modal-content > .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
</style>
