<template>
  <section class="dtc-main-section">
    <div class="dtc-search mt-3">
      <main class="left-header">
        <div>
          <h3>輔導問卷查詢:</h3>
        </div>
        <b-input-group prepend="年度">
          <b-select
            :options="years"
            v-model="year"
            @change="searchDb"
            style="font-size:16px !important"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="姓名">
          <b-input
            v-model.trim="name"
            placeholder="搜尋姓名"
            maxlength="50"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="身份證字號">
          <b-input
            v-model.trim="id"
            :autofocus="autofocus"
            maxlength="50"
            placeholder="搜尋身份證字號"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="階段">
          <b-select
            :options="stages"
            v-model="stage"
            @change="searchDb"
            style="font-size:16px !important"
          ></b-select>
        </b-input-group>

        <b-button class variant="light" @click="searchDb" size="sm">
          <i class="fas fa-search"></i>進行查詢
        </b-button>
        <b-button variant="info" @click="clearSearch" size="sm">
          <i class="fas fa-eraser"></i>清除條件
        </b-button>
      </main>
    </div>
    <header class="dtc-grid-header my-dark ml-1">
      <div>查詢</div>
      <div v-for="(item, i) in headers" :key="i" @click="sort(item)">
        {{ item.name }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
      <div
        style="
          grid-column: 1 / -1;
          padding: 20px 0px;
          font-size: 24px;
          color: #000;
        "
      >
        暫無資料
      </div>
    </main>
    <main
      class="dtc-grid-header main-dtc-body"
      v-for="(item, i) in items"
      :key="i"
      style="color: black"
      :style="
        i % 2 == 0 ? 'background-color: #F5F5F5;' : 'background-color: #E0E0E0;'
      "
    >
      <div>
        <b-button class variant="light" size="sm" @click="viewSurvey(item)">
          檢視
        </b-button>
      </div>
      <div>{{ item.Year ? item.Year : "暫無資料" }}</div>
      <div>{{ item.Name ? item.Name : "暫無資料" }}</div>
      <div>
        {{ item.Gender }}
      </div>
      <div :title="item.Position">
        {{ item.Position ? item.Position : "暫無資料" }}
      </div>
      <div :title="item.DepName">
        {{ item.DepName ? item.DepName : "暫無資料" }}
      </div>
      <div>{{ item.Identifier ? item.Identifier : "暫無資料" }}</div>
      <div>{{ item.Status ? item.Status : "暫無資料" }}</div>
      <div>{{ item.Telphone ? item.Telphone : "暫無資料" }}</div>
      <div>{{ item.Mobile ? item.Mobile : "暫無資料" }}</div>
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3" style="color: black">{{ totalCountStr }}</div>
    </footer>
    <SurveyListItem></SurveyListItem>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
import buildQuery from "odata-query";
import SurveyListItem from "./SurveyListItem";
const zero = "T00:00:00";

const headers = [
  { name: "年度", key: "Year", sortDesc: null },
  { name: "姓名", key: "Name", sortDesc: null },
  { name: "性別", key: "Gender", sortDesc: null },
  { name: "身分別", key: "Question_03", sortDesc: null },
  { name: "系別", key: "Question_05", sortDesc: null },
  { name: "身分證字號", key: "Identifier", sortDesc: null },
  { name: "階段", key: "Question", sortDesc: null },
  { name: "電話", key: "Telphone", sortDesc: null },
  { name: "手機", key: "Mobile", sortDesc: null },
];
const year = new Date().getFullYear() - 1911;
const years = new Array(7).fill().map((s, i) => year - i);
const stages = [
  {
    text: "全部",
    value: null,
  },
  {
    text: "就學中",
    value: 1,
  },
  {
    text: "訓練中",
    value: 2,
  },
  {
    text: "服務中",
    value: 3,
  },
  {
    text: "服務期滿仍繼續留任",
    value: 4,
  },
  {
    text: "服務期滿已離任",
    value: 5,
  },
];

const deps = [
  "醫學系",
  "護理系",
  "牙醫系",
  "物理治療學系",
  "營養學系",
  "職能治療學系",
  "藥學系",
  "醫技及醫檢學系",
  "呼吸治療學系",
  "生物醫學影像暨放射科學系",
  "臨床心理學系",
  "語言及聽力學系",
];

const positions = ["離島", "原住民"];

const rows = [10, 20, 50];
export default {
  name: "surveyList",
  data() {
    return {
      autofocus: true,
      totalCountStr: "",
      rows,
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      headers,
      items: [],
      years,
      year,
      name: "",
      id: "",
      stage: null,
      stages,
      searchId: "",
      search: false,
      deps,
      positions,
    };
  },
  components: {
    SurveyListItem,
  },

  methods: {
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },
    viewSurvey(item) {
      store.editPerson = { ...item };
      store.editPerson.closeBtn = true;
      this.$bvModal.show("surveyItem");
    },
    sort(item) {
      if (item.key == "Question") {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.search = false;
      this.name = "";
      this.id = "";
      this.stage = null;
      this.year = this.years[0];
      this.getData();
    },
    async getData() {
      try {
        //odata3 qs
        const page = this.currentPageNum;
        const skip = (page - 1) * this.pagingRowPerPage;
        const top = this.pagingRowPerPage;
        const orderBy = this.orderBy;
        const obj = orderBy.length ? { top, skip, orderBy } : { top, skip };
        let qs = buildQuery(obj); // + no + filter + filter2;
        if (this.search && this.id) {
          qs = this.$equalFilter("Identifier", qs, `'${this.id}'`);
        }

        if (this.search && this.name) {
          if (this.id) {
            qs = this.$appendEqualFilter("Name", qs, `'${this.name}'`);
          } else {
            qs = this.$equalFilter("Name", qs, `'${this.name}'`);
          }
        }

        // if (this.search && this.stage) {
        //   if (this.id || this.name) {
        //     qs = this.$appendEqualFilter("Question", qs, `'${this.stage}'`);
        //   } else {
        //     qs = this.$equalFilter("Question", qs, `'${this.stage}'`);
        //   }
        // }

        qs = qs.split("?")[1];

        let { Items, Count } = await axios.get(
          `LocalRecord/GetAnswerList?year=${this.year}&step=${this.stage}&` + qs
        );
        if (Count) {
          Items = Items.map((s) => ({
            Year: s.Year,
            Name: s.Name,
            Identifier: s.Identifier,
            Position: this.getOriginalAnswer(s.Question_03, this.positions)
              ? `${this.getOriginalAnswer(s.Question_03, this.positions).join(
                  ","
                )}${s.Question_03_Island ? `,${s.Question_03_Island}` : ""}${
                  s.Question_03_Native ? `,${s.Question_03_Native}` : ""
                }`
              : "",
            Status: this.stages.find((k) => k.value == s.Question)
              ? this.stages.find((k) => k.value == s.Question).text
              : "",
            // Gender: s.Gender == 1 ? "男" : s.Gender == 2 ? "女" : "其他",
            Gender:
              s.Gender == "F" || s.Gender == 1
                ? "女"
                : s.Gender == "M" || s.Gender == 2
                ? "男"
                : "其他",
            // DepName: this.getOriginalAnswer(s.Question_05, this.deps)
            //   ? this.getOriginalAnswer(s.Question_05, this.deps).join(",")
            //   : "",
            DepName: s.Question_05,
          }));
        }

        this.items = [...Items];
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        alert("" + e);
      }
    },
  },
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
  async mounted() {
    this.getData();
  },
  beforeMount() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-grid-header {
  display: grid;
  grid-template-columns: 80px repeat(3, 120px) repeat(3, 120px) 190px 1fr 1fr;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0;
  > div {
    border-right: 1px solid #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
  }
}
.my-dark > div {
  background: #6c757d;
  color: #fff;
}

.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-search {
  position: relative;
  display: grid;
  max-height: 60px;
  margin-bottom: 12px;
  grid-template-columns: 1260px 1fr max-content 10px;
  grid-gap: 1rem;
  padding-left: 10px;
  .left-header {
    display: grid;
    grid-template-columns: 180px 140px 175px 250px 280px max-content max-content;
    max-height: 40px;
    grid-gap: 10px;
    > div {
      height: 38px;
    }
  }
  .right-header {
    display: flex;
    flex-direction: column;
  }
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: auto;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
#app > section > header > div:nth-child(8) > span:nth-child(1) {
  display: none;
}
::v-deep .btn-light {
  background: #1f7cd3;
  border-color: #1f7cd3;
  color: white;
}
</style>
